<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      리뷰하기<router-link to="/review/review_select_category"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_15">
      <div class="sect_txt">리뷰할 카테고리를 선택해주세요.</div>
      <div class="sect_flash">
        <router-link :to="`/review/review_news_write/${breakNewsSeq}`">{{
          breakNewsTitle
        }}</router-link>
      </div>
      <div class="sect_list">
        <div class="box" v-for="level2 in uniqBy()" :key="level2.seq">
          <div
            class="top"
            v-if="parseInt(level2.seq) !== parseInt(breakNewsSeq)"
          >
            {{ level2.title }}
          </div>
          <ul v-if="parseInt(level2.seq) !== parseInt(breakNewsSeq)">
            <li v-for="level3 in getSubList(level2.seq)" :key="level3.sub_seq">
              <router-link
                :to="`/review/review_news_write/${level2.seq}/${level3.sub_seq}`"
                >{{ level3.sub_title }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
import _uniqBy from "lodash/uniqBy";

export default {
  data() {
    return {
      breakNewsTitle: "실시간 리뷰어 속보",
      breakNewsSeq: parseInt(process.env.VUE_APP_NEWS_REAL_TIME),
    };
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    //초기화
    this.getSubMenu();
  },
  computed: {
    ...mapState("review", ["result", "msg", "subMenuList"]),
    ...mapState("mypage", ["myInfo"]),
  },
  methods: {
    async getSubMenu() {
      await this.$store.dispatch("review/getSubMenu", { seq: 3 });
    },
    getSubList(parent_seq) {
      return this.subMenuList.filter((sub) => sub.parent_seq === parent_seq);
    },
    uniqBy() {
      return _uniqBy(this.subMenuList, "seq");
    },
  },
};
</script>

<style></style>
